.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.secureheader {
  min-height: 5vh;
  max-height: 5vh;
}

.securefooter {
  min-height: 5vh;
  max-height: 5vh;
}

.maincontent {
  min-height: 90vh;
  max-height: 90vh;
}

.scrollPage {
  overflow: auto;
  height: 80vh;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  margin-top: -75px;
}

.loader img {
  width: 150px;
  height: auto;
}

.logo-image {
  padding-top: 12px;
  max-width: 290px !important;
}

.right-panel .navbar-header {
  background-color: transparent !important;
}

.exam-palete-qs {
  /* background-color: #61dafb; */
  padding: 2px 7px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  color: black;
}

.exam-palete-qs-marked {
  /* background-color: #49cf3d; */
  background: rgba(25, 135, 84, .25);
  padding: 2px 7px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  color: black;
}

.qs-panel {
  /* background-color: #61dafb; */
  padding: 10px;
  margin-bottom: 10px;
}

.ans-panel {
  background-color: #f4f7e5;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.ans-panel-active {
  /* background-color: #7acd71; */
  background: rgba(25, 135, 84, .25);
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.ans-panel-span {
  /* background-color: #ebf4c1;
  padding: 10px;
  margin-bottom: 10px; */
}

.ans-panel-span-active {
  /* background-color: #F8BDEB; */
  background: rgba(255, 193, 7, 0.25);
  /* padding: 10px;
  margin-bottom: 10px; */
}

.full-page {
  position: absolute;
  z-index: 55555555;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  max-width: 100% !important;
  width: 100% !important;
  height: 105%;
  background-color: #f7f7f7;
  overflow-x: hidden;
}

.product-view {
  height: 300px;
  width: auto;
}